import React from 'react';

import { Blog, Features, Footer, Header, WhatGPT3 } from './containers';
import { Navbar } from './components';
import './app.css'

const App = () => {
  return (
    <div className="App">
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>
        <WhatGPT3 />
        <Features />
        <Blog />
        <Footer />
    </div>
  )
}

export default App