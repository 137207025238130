import React from 'react';
import './header.css';
import circuit from '../../assets/circuit.png';

const Linkedin = function openWin() {
  window.open("https://www.linkedin.com/in/haiy/");
};

const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">Technology with empathy leads to equality and inclusivity.</h1>
        <p>I found the passion for creating outstanding experiences with technology at the tail-end of my undergraduate art study. The first attempt to help art gallery visitors by making a web application opened my view of technical potential in every aspect of life. My diverse academic background also shaped my ethic that using technology with empathy can achieve better equality and inclusiveness besides fulfilling the needs. </p>
        <div className="gpt3__header-content__input">
          <button type="button" onClick={ Linkedin }>Let's connect</button>
        </div>
      </div>
      <div className="gpt3__header-image">
        <img src={ circuit } alt="circuit"/>
      </div>
    </div>
  )
}

export default Header 