import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import linkedinIcon from '../../assets/linkedin.png';

const Menu = () => (
  <>
  <p><a href="#home">Home</a></p>
  <p><a href="#wgpt3">Education</a></p>
  <p><a href="#features">Skills</a></p>
  <p><a href="#blog">Projects</a></p>
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

const Linkedin = function openWin() {
  window.open("https://www.linkedin.com/in/haiy/");
};

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <h1>HAI YANG</h1>
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <img src={ linkedinIcon } alt='linkedinIcon' onClick={ Linkedin }/>
        {/*<button type="button" onClick={ Linkedin }>Linkedin</button>*/}
      </div>
      <div className="gpt3__navbar-menu">
      {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
      }
      {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <Menu />
            <div className="gpt3__navbar-menu_container-links-sign">
              {/*<button type="button" onClick={ Linkedin }>Linkedin</button>*/}
              <img src={ linkedinIcon } alt='linkedinIcon' onClick={ Linkedin }/>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default Navbar