import React from 'react';
import { Feature } from '../../components';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
      <div className="gpt3__whatgpt3-feature">
        <Feature title="Education Background" />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Majored in Interdisciplinary Visual Art, Economics, and Information Technology.</h1>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature title="University of Washington" text1="Bachelor of Arts" text2="Sep 2014 – Jun 2018"/>
        <Feature title="University of Melbourne" text1="Master of Information Technology" text2="Mar 2020 – Dec 2021"/>
        <Feature title="Study Abroad in Italy" text1="UW Art School Rome" text2="Sep 2017 – Dec 2017"/>
        <Feature title="Certification" text1="AWS Cloud Practitioner" text2="Certified in June 2022"/>
      </div>
    </div>
  )
}

export default WhatGPT3