import React from 'react';
import './blog.css';
import { Article } from '../../components';
import { blog01, blog02, blog03, blog04, blog05 } from '../../components/article/imports.js';

const Blog = () => {
  return (
    <div className="gpt3__blog section__padding" id="blog">
      <div className="gpt3__blog-heading">
        <h1 className="gradient__text">A lot is happening, we are blogging about it.</h1>
      </div>
      <div className="gpt3__blog-container">
        <div className="gpt3__blog-container_groupA">
          <Article imgUrl={ blog01 } date="10 Jul 2022" title="GPT-3 and open AI is the future. Let's explore."/>
        </div>
        <div className="gpt3__blog-container_groupB">
          <Article imgUrl={ blog02 } date="10 Jul 2022" title="GPT-3 and open AI is the future. Let's explore."/>
          <Article imgUrl={ blog03 } date="10 Jul 2022" title="GPT-3 and open AI is the future. Let's explore."/>
          <Article imgUrl={ blog04 } date="10 Jul 2022" title="GPT-3 and open AI is the future. Let's explore."/>
          <Article imgUrl={ blog05 } date="10 Jul 2022" title="GPT-3 and open AI is the future. Let's explore."/>
        </div>
      </div>
    </div>
  )
}

export default Blog